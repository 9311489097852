<template>
  <div>
    <div class="main">
      <v-row>

        <v-col offset-sm="12" md="12">
          <TitleH2 class="mt-3 mb-0 mx-3" title="Dados Pessoais"/>
          <p class="mx-3 mb-5">Requerimento do Auxílio Maternidade</p>
          <FormField :fields="fields" :validate="validacao"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center mt-5" md="12" order-md="1">
          <div class="footer-pagina">
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn class="ml-3" to="/auxilio-maternidade">
                <v-icon small>mdi-arrow-left-thin </v-icon>
                Voltar
              </v-btn>
            </v-toolbar>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import auxilioService from '@/services/AuxilioService'
import utilService from '@/services/UtilService'
import associadoService from '@/services/AssociadoService'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from "@/components/atoms/StatusChip";
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'


export default {
  components: {
    TitleH2,
    FormField,
    StatusChip,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      avatar: '',
      status: "",
      cepValido: true,
      alterandoDadosFilial: false,
      exibirDialogAvisoEdicao: false,
      criandoFormulario: false,
      validacao: 0,
      dadosFilial: {},
      auxilio: {},
      ufs: [],
      receberEnderecoCep: [],
      fields: {
        nome: {
          label: 'Nome Completo',
          valueField: null,
          value: '',
          cols: 6,
          readonly: true,
          field: 'InputField',
          rules: []
        },
        dataNascimento: {
          label: 'Data de Nascimento',
          valueField: null,
          cols: 3,
          value: '',
          readonly: true,
          field: 'InputField',
        },
        idPaisNacionalidade: {
          label: 'Nacionalidade',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          readonly: true,
          clearable: false,
          rules: [],
        },
        idUfNaturalidade: {
          label: 'Naturalidade (UF)',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          readonly: true,
          clearable: false,
          rules: [],
        },
        idCidadeNaturalidade: {
          label: 'Naturalidade (Município)',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          readonly: true,
          clearable: false,
          rules: [],
        },
        numeroOab: {
          label: 'Nº OAB',
          valueField: null,
          value: '',
          cols: 2,
          readonly: true,
          rules: [],
          field: 'InputField'
        },
        idUfSubsecao: {
          label: 'Seção (UF)',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          readonly: true,
          clearable: false,
          rules: [],
        },
        idCidadeSubsecao: {
          label: 'Subseção',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          readonly: true,
          clearable: false,
          rules: [],
        },
        cpf: {
          label: 'CPF',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          readonly: true,
          rules: []
        },
        email: {
          label: 'Email ',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          readonly: true,
          rules: []
        },
        telefone: {
          label: 'Telefone',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          readonly: true,
          rules: []
        },

        dadosFilho: {
          cols: 12,
          label: 'Dados do(a) Filho(a)',
          field: 'DividerMain'
        },
        nomeFilho: {
          label: 'Nome do(a) filho(a)',
          valueField: null,
          value: '',
          cols: 6,
          readonly: true,
          field: 'InputField',
          rules: []
        },
        nascimentoFilho: {
          label: 'Data de Nascimento',
          valueField: null,
          cols: 3,
          value: '',
          readonly: true,
          field: 'InputField',
        },
      },
    }
  },

  created() {
    this.receberUFs();
    this.paises();
    this.dadosAuxilio();
  },

  methods: {
    dadosAuxilio() {
      auxilioService
        .auxilioPorId(this.$route.params.id)
        .then(({ data }) => {
          this.associadoPorId(data);
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        });
    },

    associadoPorId(auxilio) {

      associadoService
        .associadoPorId(auxilio.associado)
        .then(({ data }) => {
          const dadosAuxilio = {
            ...data,
            telefone: this.setTelefone(auxilio.ddd, auxilio.telefone),
            nascimentoFilho: auxilio.dataNascimento,
            nomeFilho: auxilio.nomeFilho,
            email: auxilio.email
          };
          this.auxilio = dadosAuxilio;
          this.criarFormulario(dadosAuxilio);
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        });
    },

    criarFormulario(data) {
      this.criandoFormulario = true;
      Object.entries(data).map(([key, value]) => {
        if(key === 'cidade') {
          this.fields['idCidadeEndereco'].valueField = Number(value);
        } else if (this.fields[key]) {
          this.fields[key].valueField = value;
        }
      })
    },

    receberUFs() {
      api.getUf().then(({ data }) => {
        this.ufs = data
        this.fields.idUfNaturalidade.items = data
        this.fields.idUfSubsecao.items = data
      })
    },

    paises() {
      utilService.paises().then(({ data }) => {
        this.listaPaises = data
        this.fields.idPaisNacionalidade.items = data
      })
    },

    receberCidade(id, nomeCampo) {
      api.getCidadeFromUF(id).then(({ data }) => {
        this.fields[nomeCampo].items = data
      })
    },

    setTelefone(ddd, telefone) {
      if(ddd && telefone) return `(${ddd}) ${telefone}`;
      if(!ddd && telefone) return `${telefone}`;
      return null;
    },
  },

  watch: {
    'fields.idUfNaturalidade.value': function (id) {
      this.receberCidade(id, 'idCidadeNaturalidade')
    },

    'fields.idUfSubsecao.value': function (id) {
      this.receberCidade(id, 'idCidadeSubsecao')
    },
  },
}
</script>

<style scoped>
  .footer-pagina { margin-top: 2rem;}
</style>
