<template>
  <div>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component class="mt-5" :is="item.component" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosAuxilioMaternidade from './DadosAuxilioMaternidade.vue'
import DadosBancariosAuxilioMaternidade from './DadosBancariosAuxilioMaternidade.vue'
import DocumentosAssociadoAuxilioMaternidade from './DocumentosAssociadoAuxilioMaternidade.vue'
import DocumentosProcessoAuxilioMaternidade from './DocumentosProcessoAuxilioMaternidade.vue'

export default {
  components: {
    DadosAuxilioMaternidade,
    DadosBancariosAuxilioMaternidade,
    DocumentosAssociadoAuxilioMaternidade,
    DocumentosProcessoAuxilioMaternidade
  },
  data() {
    return {
      tab: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-dados-auxilio',
          component: 'DadosAuxilioMaternidade'
        },
        {
          tab: 'Dados Bancários',
          content: 'tab-dados-bancarios',
          component: 'DadosBancariosAuxilioMaternidade'
        },
        {
          tab: 'Documentos do Associado',
          content: 'tab-documentos-associado',
          component: 'DocumentosAssociadoAuxilioMaternidade'
        },
        {
          tab: 'Documentos do Processo',
          content: 'tab-documentos-processo',
          component: 'DocumentosProcessoAuxilioMaternidade'
        },
      ]
    }
  }
}
</script>
