<template>
  <div>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="listaDocumentos"
      :loading="carregandoAcao"
      :itemsPerPage="listaDocumentos.length"
    >

      <template v-slot:[`item.tipo`]="{ item }">
        <span>{{ identificarTipoArquivo(item.caminho) }}</span>
      </template>


      <template v-slot:[`item.acoes`]="{ item }">
        <v-row>
          <TooltipMain top text="Ver Documento">
            <ButtonIcon
              class="mx-1"
              :primary="true"
              icon="eye"
              @click.native="verDocumeto(item.caminho)"
            />
          </TooltipMain>
        </v-row>
      </template>
    </CrudTable>

    <v-row>
      <v-col class="text-center mt-5" md="12" order-md="1">
        <div class="footer-pagina">
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn class="ml-3" to="/auxilio-maternidade">
              <v-icon small>mdi-arrow-left-thin </v-icon>
              Voltar
            </v-btn>
          </v-toolbar>
        </div>
      </v-col>
    </v-row>
  </div>

</template>

<script>
  import CrudTable from '@/components/molecules/tables/CrudTable'
  import ButtonIcon from '@/components/atoms/buttons/ButtonIcon'
  import ButtonMain from '@/components/atoms/buttons/ButtonMain'
  import TooltipMain from '@/components/atoms/TooltipMain'
  import auxilioService from '@/services/AuxilioService'

  export default {
    components: {
      CrudTable,
      ButtonIcon,
      TooltipMain,
      ButtonMain,
    },

    data() {
      return {
        keyFormValidacao: 0,
        carregandoAcao: false,
        listaDocumentos: [],
        headers: [
          { text: 'Descrição', value: 'descricao', sortable: false },
          { text: 'Tipo', value: 'tipo', sortable: false },
          { text: 'Ações', value: 'acoes', width: 150, sortable: false }
        ]
      }
    },

    created() {
      this.documentosAuxilio();
    },

    methods: {
      documentosAuxilio() {
        this.carregandoAcao = true;

        const filtro = { caama: 'false' }
        auxilioService
          .documentosPorAuxilio(this.$route.params.id, filtro)
          .then(({ data }) => {
            this.listaDocumentos = data;
          })
          .catch(() => {})
          .then(() => this.carregandoAcao = false);
      },

      verDocumeto(url) {
        window.open(url, '_blank');
      },

      identificarTipoArquivo(caminho) {
        const pesquisa = caminho.match(/(jpg|jpeg|pdf)/gi);
        const extensao = pesquisa?.slice(-1)[0] || null;

        if(!extensao) return '';

        if(extensao === 'pdf') {
          return 'PDF';
        }

        if(extensao === 'jpg' || extensao === 'jpeg') {
          return 'IMAGEM';
        }
      },
    },
  }
</script>

<style scoped>

</style>